<script lang="ts">
	import { onMount, tick } from "svelte";
	import ky from "ky";
	import { create, enforce, test, group, skip } from "vest";
	import "vest/enforce/email";
	import toast, { Toaster } from "@leodog896/svelte-french-toast";
	import type { ProblemDocument } from "http-problem-details";

	import ToastContent from "./toast-content.svelte";
	import { ValidatingStore, type ValidationProblemDetails } from "./validating-store";
	
	enum FormState {
		Loading = "loading",
		Ready = "ready",
		Success = "success",
		Error = "error",
	}

	interface ContactRequest {
		fullname: string;
		email: string;
		phone: string;
		message: string;
	}

	const initialFormData: ContactRequest = {
		fullname: "",
		email: "",
		phone: "",
		message: "",
	};

	let state: FormState = FormState.Ready;
	let token: string = "";

	const store = new ValidatingStore(initialFormData, (data: ContactRequest) => {
		test("fullname", "Bitte geben Sie Ihren Namen an", () => {
			enforce(data.fullname).isNotBlank();
		});
		test("email", () => {
			enforce(data.email)
				.message("Bitte geben Sie Ihre E-Mail-Adresse an")
				.isNotBlank()
				.message("Bitte geben Sie eine gültige E-Mail-Adresse an")
				.isEmail();
		});
		test("message", "Bitte geben Sie eine Nachricht ein", () => {
			enforce(data.message).isNotBlank();
		});
	});

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);
	});

	async function submitAsync() {
		if (state !== FormState.Ready) {
			return;
		}
		if (!store.validate()) {
			return;
		}

		try {
			state = FormState.Loading;
			let response = await ky.post(`/api/contact`, {
				headers: { RequestVerificationToken: token },
				json: store.getData(),
				throwHttpErrors: false,
			});

			if (response.ok) {
				state = FormState.Success;
				store.clear();
				toast.success(ToastContent, {
					props: {
						messageHtml: `Vielen Dank für Ihre Nachricht.`,
					},
				});
			} else if (response.status === 400) {
				state = FormState.Ready;
				let problem = await response.json<ValidationProblemDetails>();
				store.setExternalErrors(problem);
			} else {
				let problem = await response.json<ProblemDocument>();
				toast.error(ToastContent, {
					props: {
						problem,
						messageHtml: `Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns direkt unter <a href="mailto:info@pneuin.ch">info@pneuin.ch</a>.`,
					},
				});
				state = FormState.Error;
			}
		} catch (e) {
			state = FormState.Error;
			toast.error(ToastContent, {
				props: {
					messageHtml: `Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns direkt unter <a href="mailto:info@pneuin.ch">info@pneuin.ch</a>.`,
				},
			});
		}
	}
</script>

<div class="contact-form">
	<div class="columns is-multiline is-mobile">
		<div class="column is-12">
			<div class="field is-floating-in-label">
				<label for="fullname" class="label">Name</label>
				<div class="control">
					<input id="fullname" class="input" type="text" autocomplete="name" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} />
				</div>
				{#each $store.fullname.errors as error}
					<p class="help is-danger">{error}</p>
				{/each}
			</div>
		</div>
		<div class="column is-12">
			<div class="field is-floating-in-label">
				<label for="email" class="label">E-Mail</label>
				<div class="control">
					<input id="email" class="input" type="text" autocomplete="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} />
				</div>
				{#each $store.email.errors as error}
				<p class="help is-danger">{error}</p>
				{/each}
			</div>
		</div>
		<div class="column is-12">
			<div class="field is-floating-in-label">
				<label for="phone" class="label">Telefon</label>
				<div class="control">
					<input id="phone" class="input" type="text" autocomplete="tel" bind:value={$store.phone.value} on:blur={() => $store.phone.touch()} class:is-danger={$store.phone.hasErrors} />
				</div>
				{#each $store.phone.errors as error}
					<p class="help is-danger">{error}</p>
				{/each}
			</div>
		</div>
		<div class="column is-12">
			<div class="field is-floating-in-label">
				<label for="message" class="label">Nachricht</label>
				<div class="control">
					<textarea id="message" class="textarea" bind:value={$store.message.value} on:blur={() => $store.message.touch()} class:is-danger={$store.message.hasErrors}></textarea>
				</div>
				{#each $store.message.errors as error}
					<p class="help is-danger">{error}</p>
				{/each}
			</div>
			</div>
		<div class="column is-12">
			<button class="button is-primary" class:is-loading={state === FormState.Loading} on:click={submitAsync}>Senden</button>
		</div>
	</div>
</div>
<Toaster />
