import "../Styles/index.scss";

import $ from "jquery";
import { Fancybox } from "@fancyapps/ui";
import { initializeMaps } from "@wagich/bluc-map";
import svelteRetag from "svelte-retag";
import ContactForm from "./contact-form.svelte";

import "./rims";

initializeMaps();

Fancybox.bind("[data-fancybox], .is-zoomable", {
	groupAttr: "data-gallery"
});

$(".secondaryNavigationNodeContainer").hide();
$("li.primaryNavigationNode > a").on("mouseenter touch click", function (e) {
	$(this).siblings(".secondaryNavigationNodeContainer").show();
	var aktionenLink = $(this).attr("href");
	if (aktionenLink !== "/aktionen" && (!$("body").hasClass("home"))) {
		e.preventDefault();
	}

	$(".primaryNavigationNode").on("mouseleave", function (e) {
		$(this).children(".secondaryNavigationNodeContainer").hide();
	});
});

svelteRetag({
	component: ContactForm,
	tagname: "contact-form",
});
